import React from "react";
import { NavLink } from "react-router-dom";

function Sidebar({ user, match }) {
  return (
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
      style={{ marginTop: ".5%", display: "flex" }}
    >
      <div className="sidebar-sticky pt-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/admin/dashboard"
              activeclassname="active"
            >
              <i className="fas fa-home"></i> Dashboard
            </NavLink>
          </li>
          {user && user.role !== "api-access" && (
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/admin/profile"
                activeclassname="active"
              >
                <i className="far fa-user"></i> Profile
              </NavLink>
            </li>
          )}
          {user && (user.role === "admin" || user.role === "super-admin") && (
            <>
              <h3 className="d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Admin</span>
              </h3>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/admin/locations"
                  activeclassname="active"
                >
                  <i className="fas fa-map-marked-alt"></i> Locations
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/admin/rounds/1"
                  activeclassname="active"
                >
                  <i className="far fa-bell"></i> Classes
                </NavLink>
              </li>
            </>
          )}
        </ul>
        {user && user.role === "super-admin" && (
          <>
            <h3 className="d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Super Admin</span>
            </h3>

            <ul className="nav flex-column mb-2">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/admin/users"
                  activeclassname="active"
                >
                  <i className="fas fa-users"></i> Users
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/admin/logs"
                  activeclassname="active"
                >
                  <i className="fas fa-book"></i> Logs
                </NavLink>
              </li>
            </ul>
          </>
        )}
      </div>
    </nav>
  );
}

export default Sidebar;
