import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/auth/AuthContext";
import Spinner from "../../layout/Spinner";
import LockButton from "../../common/LockButton";
import AlertContext from "../../../context/alert/AlertContext";

const Profile = () => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const authContext = useContext(AuthContext);
  const {
    loadUser,
    user,
    loading,
    error,
    updateMe
  } = authContext;

  const [locked, setLocked] = useState(true);
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    loadUser();
    if (user) {
      setUserState(user);
    }
    // eslint-disable-next-line
  }, [error, loading]);

  const [userState, setUserState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });

  const {
    firstName,
    lastName,
    email,
    role,
  } = userState;
  
  const onChange = (e) => {
    setUserState({ ...userState, [e.target.name]: e.target.value });
  };

  const onChangePassword = (e) => {
    setNewPassword(e.target.value);
  }
  
  const onSubmit = (e) => {
    e.preventDefault();

    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    if (newPassword) {
      // check if new password passes validation
      if (newPassword.match(passwordRegex) !== null) {
        updateMe({...userState, password: newPassword});
        setAlert({
          message: "User profile and password updated.",
          type: "success",
        });
      } else {
        setAlert({
          message: "Password must be at least 8 characters and have at least one uppercase and lowercase letter, number, and symbol.",
          type: "error",
        });
        return;
      }
    } else {
      // update profile without resetting password
      updateMe(userState);
      setAlert({
        message: "User profile updated.",
        type: "success",
      });
    }

    setUserState({ ...userState, password: "" });
    setNewPassword("");
    setLocked(true);
  };

  useEffect(() => {
  }, [user])

  return (
    <form onSubmit={onSubmit}
    >
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2>
          {(user.role === 'admin' || user.role === 'super-admin') && (
            <LockButton locked={locked} setLocked={setLocked} />
          )}{" "}Profile
        </h2>
        <div className="btn-group ">
          {!locked && (user.role === 'admin' || user.role === 'super-admin') && (
            <button type="submit" className="btn btn-primary">
              <i className="fas fa-save"></i> Update
            </button>
          )}
        </div>        
      </div>
      {user && !loading ? (
        <>
          <div className="row">
            <div className="form-group col-md-3 col-sm-12">
              <label htmlFor="firstName">First Name</label>
              <input
                name="firstName"
                type="text"
                className="form-control"
                value={firstName}
                onChange={onChange}
                required={true}
                disabled={locked}
              />
            </div>
            <div className="form-group col-md-3 col-sm-12">
              <label htmlFor="lastName">Last Name</label>
              <input
                name="lastName"
                type="text"
                className="form-control"
                value={lastName}
                onChange={onChange}
                required={true}
                disabled={locked}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-3 col-sm-12">
              <label htmlFor="email">Email</label>
              <input
                name="email"
                type="email"
                className="form-control"
                value={email}
                onChange={onChange}
                required={true}
                disabled={locked}
              />
            </div>
            <div className="form-group col-md-3 col-sm-12">
              <label htmlFor="role">Role</label>
              <input
                name="role"
                type="text"
                className="form-control"
                value={role}
                onChange={onChange}
                required={true}
                disabled={locked}
              />
            </div>
          </div>
          {(user.role === 'admin' || user.role === 'super-admin') && (
          <div className="row">
            <div className="form-group col-md-3 col-sm-12">
              <label htmlFor="password">Reset Password</label>
              <input
                name="password"
                type="password"
                className="form-control"
                value={newPassword}
                onChange={onChangePassword}
                placeholder="New Password"
                disabled={locked}
                aria-describedby="passwordHelpBlock"
              />
            {!locked && <div id="passwordHelpBlock" class="form-text">Leave field blank unless you want to reset this user's password.</div>}
            </div>
          </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </form>
  );
};

export default Profile;
