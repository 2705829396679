export const videoValidation = (v) => {
  return new Promise((resolve, reject) => {
    const validateVideo = document.createElement("video");
    validateVideo.src = v.videoLink;
    validateVideo.onloadedmetadata = () => {
      resolve(true);
    };
    validateVideo.onerror = () => {
      reject(false);
    };
  });
};

export const updateInvalidLink = (results) => {
  const idx = results?.order;
  const validLinks = [
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/5_MIN_COUNDOWN_TIMER.mp4?_t=1730398808",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/IMPORTANT/WARM_UP_1.mp4?_t=1730482488",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/GLOVES_ON_WORLD_OFF_NO_AUDIO.mp4?_t=1739814242",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/IMPORTANT/COMBO_1.mp4?_t=1739899786",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/IMPORTANT/COMBO_2.mp4?_t=1739899812",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/IMPORTANT/COMBO_3.mp4?_t=1739899837",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/IMPORTANT/COMBO_4.mp4?_t=1739899873",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/IMPORTANT/COMBO_5.mp4?_t=1739899903",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/IMPORTANT/COMBO_6.mp4?_t=1739899930",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/IMPORTANT/COMBO_7.mp4?_t=1739899954",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/IMPORTANT/COMBO_8.mp4?_t=1739899974",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/IMPORTANT/CORE_1.mp4?_t=1730482445",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/IMPORTANT/STRETCHES_1.mp4?_t=1730482459",
    "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/ILKB_SCREENS/DEV_NO_MODIFY/PRODUCTION/END_CLASS_SCREEN.mp4?_t=1730398811",
  ];
  return {
    videoLink: validLinks[idx],
    validated: true,
    order: idx,
    failedClientValidation: true,
  };
};
