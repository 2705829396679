import React, { useEffect, useContext } from "react";
import AuthContext from "../../../context/auth/AuthContext";
import DashboardSchedule from "../dashboard/DashboardSchedule";
import Spinner from "../../layout/Spinner";
import ContentStatus from "./ContentStatus";
import RollingScheduleStatus from "./RollingScheduleStatus";

const Dashboard = ({ url }) => {
  const authContext = useContext(AuthContext);
  const { loadUser, loading, error, user } = authContext;

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, [loading, error]);

  useEffect(() => {
    // window.alert("Page Load");
  }, []);

  return (
    <>
      <div
        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
        style={{ position: "" }}
      >
        <h2>Dashboard</h2>
      </div>

      <div className="pb-2"></div>

      {loading ? (
        <span>
          Loading dashboard data...
          <Spinner />
        </span>
      ) : (
        user && (
          <>
            {user?.role &&
              (user.role === "admin" || user.role === "super-admin") && (
                <>
                  <ContentStatus />
                  <RollingScheduleStatus />
                </>
              )}
            <DashboardSchedule user={user} />
          </>
        )
      )}
    </>
  );
};

export default Dashboard;
