import React, { useState, useEffect, useContext } from "react";
import WorkoutContext from "../../../context/workout/WorkoutContext";

const RollingScheduleStatus = () => { 

  const workoutContext = useContext(WorkoutContext);
  const {
      loading,
      workouts,
      getWorkoutsForRound,
      error,
      clearCurrent,
      clearWorkouts,
  } = workoutContext;

  // Rolling schedule status consts
  const [nextWeekWorkouts, setNextWeekWorkouts] = useState([]);
  const [workoutsMissing, setWorkoutsMissing] = useState(true);

  // For fetching class workouts
  const roundNumber = 1;

  const weekDays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  // Get date for rolling schedule
  const _d = new Date();
  const today = _d.getDay();

  // Generate an array of date strings for the next week
  function getNextWeekDates() {
    const dates = [];
    const today = new Date();

    // Loop through the next 7 days
    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      
      // Format the date as a string (YYYY-MM-DD)
      const formattedDate = date.toISOString().split('T')[0];
      
      dates.push(formattedDate);
    }
    return dates;
  }
  
  // Get the dates for the next week
  const nextWeekDates = getNextWeekDates();
  
  // build table headers for rolling schedule
  const weekDayHeaders = weekDays.slice(today).concat(weekDays.slice(0, today));

  // send user to classes page
  const handleWorkoutClick = () => {
    window.location = "rounds/1";
  }
  
  useEffect(() => {
    const workoutsByDate = [];
    for (const date of nextWeekDates) {
        const filteredWorkouts = workouts.filter(workout => {
            return (workout.startDate.split('T')[0] <= date && workout.endDate.split('T')[0] >= date)
        });
        workoutsByDate.push(filteredWorkouts);
    }
    setNextWeekWorkouts(workoutsByDate);
       // eslint-disable-next-line
  }, [workouts, loading])
  
  useEffect(() => {
    getWorkoutsForRound(roundNumber);
    clearCurrent();
  
    // Clear workouts on unmount
    return () => {
      clearWorkouts();
    };
    // eslint-disable-next-line
  }, [error, loading]);

  useEffect(() => {
    for (const day of nextWeekWorkouts) {
        if (!day.length) {
            setWorkoutsMissing(true);
            return;
        }
    }
    setWorkoutsMissing(false);
   // eslint-disable-next-line
  }, [nextWeekWorkouts])

  return (
    <>
      <div className="content-status-container pb-4">
        <h3 style={{ display: "inline-block" }}>
          <span>Rolling Schedule Status</span>
        </h3>
        {" "}
        {nextWeekWorkouts && (
          <>
            <span>
                {workoutsMissing ? (
                    <i
                    className="fas fa-times-circle text-danger"
                    title="Missing workouts detected"
                    ></i>
                ) : (
                    <i
                    className="fas fa fa-check-circle text-success"
                    ></i>
                )}
            </span>
            {!loading && (
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-sm">
                  <thead className="thead-dark">
                    <tr className="text-center">
                      {weekDayHeaders?.map((day, index) => (
                        <th key={index} className="rolling-schedule-th" style={day === weekDays[today].toLowerCase() ? {background: 'darkgreen'} : {background : '#343a40'}}>{day.toUpperCase()} </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    <tr>
                      {nextWeekDates?.map((day, index) => (
                        <td key={index} className="workout-item-wrapper" role="button" title={day} onClick={() => handleWorkoutClick()}>
                          {nextWeekWorkouts[index]?.length ? <i class="fa fa-check text-success"></i> : <i class="fa fa-times text-danger"></i>}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RollingScheduleStatus;
